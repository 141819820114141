import React, { Suspense } from 'react'
import {Helmet} from "react-helmet";


const TermsAndConditionsPage = React.lazy(()=> import('./ComponentsPages/TermsAndConditionsPage')); 

 
 

function TermsAndConditions() {
  return (
    <div className='relative  '>  

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'             
                />
                <meta name='keywords' 
                      content='  '        
                />
                <title>Terms & Conditions</title>
                <link rel="canonical" href="https://www.hydrokings.in/terms&conditions" />
            </Helmet>



        <Suspense fallback={<div>Loading...</div>}>

            <TermsAndConditionsPage/>
             
        </Suspense>
    </div>
  )
}

export default TermsAndConditions;