import React from 'react'
import {Link} from "react-router-dom";

 

const Error =() =>  {
  return (

    <div>
      
        <section class="page_404">
                        
                <div class="container">
                     <div class="row"> 
                        <div class="col-sm-12 ">
                            <div class="col-sm-10 col-sm-offset-1  text-center">
                                <div class="four_zero_four_bg">
                                    <h1 class="text-center ">404</h1>
                                </div>
    
                                <div class="contant_box_404">
                                    
                                    <h3 class="h2">
                                        
                                    </h3>
                                    <p className="text-[#EC732D] not-italic font-bold text-[25px] md:text-[30px] leading-[29px]  ">
                                      Look Like you're Lost
                                    </p>
                                    
                                    <p className="mb-10 text-[#000] not-italic font-bold text-[20px] md:text-[25px] leading-[40px]  ">
                                        The Page you are Looking for not Avaible!
                                    </p>

                                     
    
                                    <Link to="/" className=" ">
                                        <button className=" w-[200px] bg-[#e3242b] p-[12px_28px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] 
                                                           rounded-full hover:bg-[red] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring 
                                                           focus:ring-violet-300 hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    
                                                           shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] ">
                                          
                                          <span className="text-[#fff] tracking-[0.05em] font-bold   text-[20px] ">                   
                                              Go to Home
                                          </span>

                                        </button>
                                   </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

    </div>
  )
}

 

export default Error
