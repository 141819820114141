import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
 

const AboutBanner = React.lazy(()=> import('../screens/ComponentsPages/AboutBanner'));
const AboutInfo = React.lazy(()=> import('../screens/ComponentsPages/AboutOurCompanyInfo'));
const AboutRange = React.lazy(()=> import('../screens/ComponentsPages/AboutRange'));
const ScrillingImages = React.lazy(()=> import('../screens/ComponentsPages/ScrillingImages'));
const HReview = React.lazy(()=> import('./ComponentsPages/HomeReviewPeople')); 
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));
const WavyBackgroundPage = React.lazy(()=> import('./ComponentsPages/HomeCallInfo')); 
const AboutCertificateImage = React.lazy(()=> import('./ComponentsPages/AboutCertificateImage')); 

 

const About  = () => {
    return (
        <div className='relative'> 

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Established in 2000, Hydroking Industries has emerged as a leading provider of advanced hydraulic machines and comprehensive industrial mold solutions. With 10 years of experience in the industry, we have garnered a reputation for excellence, innovation, and customer satisfaction.
                               Since inception, Hydroking Industries has been committed to pushing the boundaries of hydraulic technology. We began with a vision to deliver high-quality, precision-engineered hydraulic machines that meet the rigorous demands of modern industrial applications.'
                               
                />
                <meta name='keywords' 
                      content=' '        
                />
                <title>About</title>
                <link rel="canonical" href="https://www.hydrokings.in/about" />
            </Helmet>



            <Suspense fallback={<div>Loading...</div>}>
                 
                   
                {/* <AboutPageSection1/>
                <AboutPageSection2/>
                <HomepageSectionFive/> 
                <AboutCallPage/> */}
                 
                    <AboutBanner/>
                    <AboutInfo/>
                    <ScrillingImages/>
                    <AboutCertificateImage/>
                    <AboutRange/>
                    <HoverImageLinks/>
                    <HReview/>
                    <WavyBackgroundPage/>


                
            </Suspense>
        </div>

        );
    }
export default About