import React, { Suspense } from 'react'
import {Helmet} from "react-helmet";


const SliderImages = React.lazy(()=> import('./ComponentsPages/HomeHeroSection')); 
const HomeSectionOne = React.lazy(()=> import('./ComponentsPages/HomeSection1')); 
const ShuffleHero = React.lazy(()=> import('./ComponentsPages/DieMoldImages')); 
const Features = React.lazy(()=> import('./ComponentsPages/HomeFeatures')); 
const HomeWhyChooseUs = React.lazy(()=> import('./ComponentsPages/HomeWhyChooseUs')); 
const Services = React.lazy(()=> import('./ComponentsPages/HomeServices')); 
const HomeProducts = React.lazy(()=> import('./ComponentsPages/HomeProducts'));  
const HomeGalleryImages = React.lazy(()=> import('./ComponentsPages/HomeGalleryImages')); 
const Htrust = React.lazy(()=> import('./ComponentsPages/HomeTrust')); 
const HReview = React.lazy(()=> import('./ComponentsPages/HomeReviewPeople')); 
const WavyBackgroundPage = React.lazy(()=> import('./ComponentsPages/HomeCallInfo')); 
const Form = React.lazy(()=> import('./ComponentsPages/HomeForm')); 
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));
const Spotlightcardspage = React.lazy(()=> import('../screens/ComponentsPages/HomeSolutionPage'));
 


function Home() {
  return (
    <div className='relative  '>  

          <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'
                               
                />
                <meta name='keywords' 
                      content='  '        
                />
                <title>Hydroking Industries</title>
                <link rel="canonical" href="https://www.hydrokings.in" />
          </Helmet>



          <Suspense fallback={<div>Loading...</div>}>

            <SliderImages/>
            <HomeSectionOne/>
            <ShuffleHero/>
            <Features/>
            <Spotlightcardspage/>
            <HomeWhyChooseUs/>
            <Services/>
            <HomeProducts/>
            <HomeGalleryImages/>
            <HoverImageLinks/>
            <Htrust/>
            <HReview/>
            <WavyBackgroundPage/>
            <Form/>

        </Suspense>
    </div>
  )
}

export default Home;