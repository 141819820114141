import React, { Suspense } from 'react'
import {Helmet} from "react-helmet";



const DieMoldPageSection1 = React.lazy(()=> import('./ComponentsPages/DieMoldPageSection1')); 
const DieMoldPageSection2 = React.lazy(()=> import('./ComponentsPages/DieMoldPageSection2')); 
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));



function DieMold() {
  return (
    <div className='relative  '>  

          <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'              
                />
                <meta name='keywords' 
                      content=' '        
                />
                <title>Die & Molds</title>
                <link rel="canonical" href="https://www.hydrokings.in/die-mold" />
          </Helmet>



        <Suspense fallback={<div>Loading...</div>}>

            <DieMoldPageSection1/>
            <DieMoldPageSection2/>
            <HoverImageLinks/>
            
        
        </Suspense>
    </div>
  )
}

export default DieMold;