import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
 

const ContactBanner = React.lazy(()=> import('../screens/ComponentsPages/ContactBanner'));
const WavyBackgroundPage = React.lazy(()=> import('./ComponentsPages/HomeCallInfo')); 
const Form = React.lazy(()=> import('./ComponentsPages/HomeForm')); 
const HomeFormPage = React.lazy(()=> import('../screens/ComponentsPages/HomeFormPage'));


  
const ContactUs  = () => {
    return (
        <div className='relative'> 

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Our Phone Number : 8090209080, 9090434242 
                               Our Address : Near Motiyar Dharam Kanta, Opposite Karni Riico, Pugal Road, Bikaner-334004 (Rajasthan), India'
                               
                />
                <meta name='keywords' 
                      content=' '        
                />
                <title>Contact Us</title>
                <link rel="canonical" href="https://www.hydrokings.in/contactUs" />
            </Helmet>


        <Suspense fallback={<div>Loading...</div>}>
            
                <ContactBanner/>
                <WavyBackgroundPage/>
                <Form/>
                <HomeFormPage/> 

             
         </Suspense>
        </div>

        );
    }
export default ContactUs