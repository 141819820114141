import React from 'react'


function CNCMachine() {
    return(


        <div>


            <div className='h-content w-full '>
                <img src='images/CNC machine.jpg' alt='machine background' className='h-full w-full'/>
            </div>


        </div>


    )
}

export default CNCMachine;