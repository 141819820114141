import React from 'react'


function LatheMachine() {
    return(


        <div>


            <div className='h-content w-full '>
                <img src='images/lathe machine.jpg' alt='machine background' className='h-full w-full'/>
            </div>


        </div>


    )
}

export default LatheMachine;