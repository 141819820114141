import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
// import AboutPageSection1 from '../screens/ComponentsPages/AboutPageSection1'
// import GalleryPage from '../screens/ComponentsPages/GalleryPage'
// import AboutCallPage from '../screens/ComponentsPages/AboutCallPage'

// const AboutPageSection1 = React.lazy(()=> import('../screens/ComponentsPages/AboutPageSection1'));
// const GalleryPage = React.lazy(()=> import('../screens/ComponentsPages/GalleryPage'));

const GalleryPageImages1 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesOne'));
const GalleryPageImages2 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesTwo'));
const GalleryPageImages3 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesThree'));
const GalleryPageImages4 = React.lazy(()=> import('./ComponentsPages/GalleryPageImagesFour'));
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));



const Gallery  = () => {
    return (
        <div className='relative'>

        <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'           
                />
                <meta name='keywords' 
                      content=' '        
                />
                <title>Gallery</title>
                <link rel="canonical" href="https://www.hydrokings.in/gallery" />
          </Helmet>

            <Suspense fallback={<div>Loading...</div>}>

            <div className='bg-purpleHeader'>
                 
                {/* <AboutPageSection1/>
                <GalleryPage/>
                 
                <AboutCallPage/> */}
                 
                <GalleryPageImages1/>
                <GalleryPageImages2/>
                <GalleryPageImages3/>
                <GalleryPageImages4/>
                <HoverImageLinks/>


            </div>
        </Suspense>

        </div>

        );
    }
export default Gallery