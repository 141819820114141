import React, { Suspense } from 'react'
import {Helmet} from "react-helmet";


const SliderImages = React.lazy(()=> import('./ComponentsPages/HomeHeroSection')); 
const HomeProducts = React.lazy(()=> import('./ComponentsPages/HomeProducts'));  
const HReview = React.lazy(()=> import('./ComponentsPages/HomeReviewPeople')); 
const WavyBackgroundPage = React.lazy(()=> import('./ComponentsPages/HomeCallInfo')); 
const HoverImageLinks = React.lazy(()=> import('../screens/ComponentsPages/PagesLinkAnimation'));

 
 

function Product() {
  return (
    <div className='relative  '>  

          <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'           
                />
                <meta name='keywords' 
                      content='  '        
                />
                <title>Our Products</title>
                <link rel="canonical" href="https://www.hydrokings.in/products" />
          </Helmet>



        <Suspense fallback={<div>Loading...</div>}>

            <SliderImages/>
             
            <HomeProducts/>

            <HoverImageLinks/>
             
            <HReview/>
             
            <WavyBackgroundPage/>
            

        </Suspense>
    </div>
  )
}

export default Product;