import React, { Suspense } from 'react'
import {Helmet} from "react-helmet";

const BlogPage = React.lazy(()=> import('./ComponentsPages/BlogPage')); 

function Blog() {
  return (
    <div className='relative'>  


            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Welcome to Hydroking Industries, your Trusted Partner in Hydraulic Machines and Comprehensive Industrial Mold Solutions.'              
                />
                <meta name='keywords' 
                      content=' '        
                />
                <title>Blogs</title>
                <link rel="canonical" href="https://www.hydrokings.in/blogs" />
            </Helmet>



        <Suspense fallback={<div>Loading...</div>}>

            <BlogPage/>
             
        </Suspense>
    </div>
  )
}

export default Blog;